import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
// import MainLayout from "src/layouts/MainLayout";
import DocsLayout from "src/layouts/DocsLayout";
import PreLaunchHomeView from "src/views/home/PreLaunchHomeView";
import LoadingScreen from "src/components/LoadingScreen";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFoundView")),
  },
  {
    path: "/docs",
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: "/docs",
        component: () => <Redirect to="/docs/welcome" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: "*",
    // layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/",
        component: PreLaunchHomeView,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
