import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Fade,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import Logo from "src/components/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    scrollSnapType: "y mandatory",
    overflowY: "scroll",
    height: "100vh",
  },
  scrollSnapItemHero: {
    scrollSnapAlign: "start",
    height: "100vh",
    position: "relative",
    width: "100vw",
  },
  scrollSnapItem: {
    scrollSnapAlign: "start",
    minHeight: "100vh",
    // paddingTop: theme.spacing(12)
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  viewPort: {
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100vw",
    height: "100vh",
    color: "white",
    // background: 'linear-gradient( rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0))'
    //background: 'linear-gradient( rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0))'
  },
  scrollSnapItemHeroContact: {
    scrollSnapAlign: "start",
    height: "20vh",
    [theme.breakpoints.down("sm")]: {
      height: "30vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "60vh",
    },
    position: "relative",
    width: "100vw",
  },
  viewPortContact: {
    backgroundColor: theme.palette.text.secondary,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    width: "100vw",
    height: "20vh",
    [theme.breakpoints.down("sm")]: {
      height: "30vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "60vh",
    },
    color: "white",
    // background: 'linear-gradient( rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0))'
    //background: 'linear-gradient( rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0))'
  },
  startOrderButton: {
    fontSize: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
    width: 250,
  },
  contactUsButton: {
    fontSize: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
    width: 250,
  },
  video: {
    objectFit: "cover",
    width: "100vw",
    height: "100vh",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logo: {
    width: 100,
    height: "auto",
    [theme.breakpoints.down("md")]: {
      width: "calc(100vw*0.8)",
      height: "auto",
    },
  },
  mobileLogo: {
    width: "calc(100vw*0.8)",
    height: "auto",
  },
  title: {
    borderLeftWidth: "5px",
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.secondary.main,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
}));

const HomeView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Hann Hwa Fabrics Sdn Bhd"
      id="sContainer"
    >
      <div id="s1" className={classes.scrollSnapItemHero}>
        {/* <video
          autoPlay
          playsInline
          loop
          muted
          poster={`/static/images/hero_image.jpg`}
          className={classes.video}
        >
          <source
            src={
              'https://firebasestorage.googleapis.com/v0/b/fancyapiece-f2a22.appspot.com/o/Hero.mp4?alt=media'
              // 'https://afirebasestorage.googleapis.com/v0/b/fancyapiece-f2a22.appspot.com/o/Hero.mp4?alt=media'
            }
            type="video/mp4"
          />
        </video> */}
        {/* <img
          src={`/static/images/hero_image.jpg`}
          className={classes.video}
        /> */}
        <Fade in timeout={2000}>
          <Box className={classes.viewPort}>
            <Container maxWidth="lg">
              <Logo className={classes.logo} />
              <Box mt={3}>
                <Typography variant="h3" color="textPrimary">
                  Coming Soon
                </Typography>
              </Box>
            </Container>
          </Box>
        </Fade>
      </div>
    </Page>
  );
};

export default HomeView;
